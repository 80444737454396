import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import Section from '../components/Section';
import Layout from '../components/Layout';
import ProjectHeader from '../components/ProjectHeader';
import Footer from '../components/Footer';

const Title = styled(Text)`
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const GrandTitle = styled(Text)`
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  margin-top: 4rem;
`;

const ImprintContent = () => (
  <Section.Container id="Imprint">
    <StaticQuery
      query={graphql`
        query Imprint {
          __typename
          contentfulImprint {
            name
            straeUndHausnummer
            plzUndStadt
            telefon
            mail
            haftungFrInhalte {
              haftungFrInhalte
            }
            haftungFrLinks {
              haftungFrLinks
            }
            urheberrecht {
              urheberrecht
            }
            copyright {
              copyright
            }
          }
        }
      `}
      render={({ contentfulImprint }) => {
        return (
          <Flex justifyContent="center" flexWrap="wrap">
            <Box width={[1, 1, 3 / 8]} px={[1, 2, 4]}>
              <GrandTitle>Imprint / Legal</GrandTitle>
              <Title>Angaben gemäß § 5 TMG:</Title>
              <Text>{contentfulImprint.name}</Text>
              <Text>{contentfulImprint.straeUndHausnummer}</Text>
              <Text>{contentfulImprint.plzUndStadt}</Text>

              <Title>Kontakt</Title>
              <Text>
                Telefon:&nbsp;
                {contentfulImprint.telefon}
              </Text>
              <Text>
                Mail:&nbsp;
                {contentfulImprint.mail}
              </Text>
            </Box>

            <Box width={[1, 1, 5 / 8]} px={[1, 2, 4]}>
              <GrandTitle>Haftungsausschluss / Disclaimer</GrandTitle>
              <Title>Haftung für Inhalte</Title>
              <Text>{contentfulImprint.haftungFrInhalte.haftungFrInhalte}</Text>

              <Title>Haftung für Links</Title>
              <Text>{contentfulImprint.haftungFrLinks.haftungFrLinks}</Text>

              <Title>Urheberrecht</Title>
              <Text>{contentfulImprint.urheberrecht.urheberrecht}</Text>

              <Title>Copyright</Title>
              <Text>{contentfulImprint.copyright.copyright}</Text>
            </Box>
          </Flex>
        );
      }}
    />
  </Section.Container>
);

const IndexPage = () => (
  <Layout>
    <ProjectHeader />
    <ImprintContent />
    <Footer />
  </Layout>
);

export default IndexPage;
